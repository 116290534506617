import React, {useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Grid,
    Checkbox,
    Link,
    Card,
    Avatar,
} from '@mui/material';
import {Star as StarIcon} from 'react-feather';
import { Alert } from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import axios from "../../../utils/axios";

function UploadConfirmation({
                                enquiryType,
                                stepName,
                                enquiry,
                                ...rest
                        }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState({});
    const [isLoading, setLoading] = useState(true);

    const loadCompleteEnquiry = async (sendCount) => {
        if(sendCount == undefined) {
            sendCount = 1;
        } else {
            sendCount++;
        }

        try {
            let pollingResponse = await axios.get('/web/ui/bulkUser/findEnquiry', {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId}});
            if(pollingResponse.data && pollingResponse.data.ticketId) {
                enquiry.ticketId = pollingResponse.data.ticketId;
                setLoading(false);
            } else if(sendCount <= 20) {
                setTimeout(() => loadCompleteEnquiry(sendCount), 3000);
            } else {
                setErrors({submit: 'Unable to confirm Service Desk enquiry'});
                setAlertVisible(true)
                setLoading(false);
            }
        } catch (err) {
            if(sendCount <= 20) {
                setTimeout(() => loadCompleteEnquiry(sendCount), 3000);
            } else {
                setErrors({submit: 'Unable to confirm Service Desk enquiry - ' + err.message});
                setAlertVisible(true)
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (enquiry.bulkUserUpdateId) {
            loadCompleteEnquiry();
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {errors.submit && isAlertVisible &&(
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="info"
                        >
                            {errors.submit}
                        </Alert>
                    </Box>
                )}
                <Box
                    maxWidth={600}
                    mx="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Avatar>
                            <StarIcon />
                        </Avatar>
                    </Box>
                    {isLoading && (
                        <Fragment>
                            <Box mt={2}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    align="center"
                                >
                                    <p>Your account upload is being processed</p>
                                </Typography>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                                >
                                <CircularProgress/>
                            </Box>
                        </Fragment>
                    )}

                    {!isLoading && enquiry.ticketId && (
                        <Fragment>
                            <Box mt={2}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    align="center"
                                >
                                    Your account upload has been completed.
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    align="center"
                                    paragraph={true}
                                >
                                    Your Service Desk ticket number is <Box component="span" fontWeight="fontWeightBold">{enquiry.ticketId}</Box>.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    align="center"
                                    paragraph={true}
                                >
                                    You will also receive an email from our Service Desk with your ticket number for reference. If you do not receive this, please check your spam/junk mail.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    align="center"
                                    paragraph={true}
                                >
                                    It is also advisable to alert your IT department to <Link href="https://australiatechsupport.zendesk.com/hc/en-gb/articles/11105117085074-How-can-my-school-unblock-emails-and-domains-required-for-my-Cambridge-digital-resources" underline="always" target="_blank"><Box component="span" fontWeight="fontWeightBold">this article</Box></Link> regarding whitelisted domains and addresses to ensure all communication from our service desk is received.
                                </Typography>
                            </Box>
                        </Fragment>
                    )}
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            component={RouterLink}
                            to="/app/service"
                        >
                            Another Enquiry
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

UploadConfirmation.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object
};

export default UploadConfirmation;
